.btn {
  &:focus {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.btn-primary {
  background: $gradient-blue;
  border-color: transparent !important;

  &.bg-gradient-green {
    background: $gradient-green;
  }
}

.btn-link {
  font-family: "Roboto";
}

.btn-outline-secondary {
  border: 1px solid #081D3F;
  color: #081D3F;

  &:hover {
    color: #081D3F;
    border: 1px solid #081D3F;
    background-color: transparent;
  }

  &:focus {
    box-shadow: none !important;
  }

  &:active {
    &:focus {
      box-shadow: none !important;
    }

    color: #081D3F !important;
    border: 1px solid #081D3F !important;
    background-color: transparent !important;
  }
}

.btn:disabled {
  background: $gradient-gray;
  color: $white;
  opacity: 0.4;
}

.btn-outline-blue {
  border: 1px solid $primary;
  color: $primary;
}

.btn-messages{
  background:$gradient-blue-msgs;
  color:$white;
}

.btn-gradient-orange {
  &:hover {
    color:$white;
  }
  background:$gradient-orange;
  color:$white;
}
