#navbarNotificationSystem {
  i {
    font-size: 24px;
  }

  h5 {
    font-size: 10px !important;
  }

  a {
    text-decoration: none !important;
  }

  small {
    font-size: 10px;
  }

  hr {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .total_unread_count {
    width: 20px;
    height: 20px;
    background: $red;
    display: block;
    text-align: center;
    border-radius: 10px;
    font-weight: normal;
    position: absolute;
    top: 0;
    left: 24px;
  }

  .small-text {
    font-size: 12px;
  }

  .dropdown-menu {
    min-width: 285px !important;
    background-color: transparent;
    border: none;
  }

}

#messages_block {

  .title {
    font-weight: bold;
  }

  .small-text {
    font-size: 10px;
  }

  .uploaded-files {
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 30px;

    .uploaded-file-wrapper {
      > img {
        width: 37px;
        height: 37px;
      }
    }

  }

  .notifications {
    .message-from {
      font-size: 14px !important;
    }

    .last-message {
      font-size: 12px !important;
    }

    &.hide {
      display: none;
    }
  }

  .unread_messages {
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: 10px;
    color: $white;
    background: $gradient-notification;
    font-size: 10px;
    padding-top: 1px;
  }

  h5.chat-label {
    font-size: 10px !important;
  }

  .speech-bubble {
    width: 10px;
    height: 10px;
    position: relative;
    background: $blue-light;
    border-radius: .4em;
  }

  a {
    text-decoration: none;
  }

  .form-control {
    height: 40px !important;
  }

  #messages {

    a {
      &:hover {
        color: $blue-light;
      }

      font-weight: bold;
    }

    min-height: 250px;
    height: 100%;
    position: relative;
  }

  .card.messages {
    .card-body {
      overflow-y: scroll;
    }
  }

  .opened-chats {

    .card-body {
      overflow-y: scroll;
    }

    h5 {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .message-wrapper-opened_chat {

    .message-from {
      font-family: Roboto;
      font-size: 12px;
    }

    .last-message {
      text-transform: capitalize;
      color: $gray-darker;
      font-size: 12px;
      text-decoration: none;
    }

    margin: 0;
    margin-bottom: 10px;
    padding: 10px;
    background-color: $gray_messages;
    display: block;
    border-radius: 8px;

    &:hover {
      background-color: $gray_messages_over;
    }

    &.active {
      background-color: $gray_messages_over;
    }

  }

  .card-body {
    padding-top: 15px;
    position: relative;
    display: block;
  }

  .breadcrumb {
    margin: 0;
    padding: 0;

    h5 {
      margin-bottom: 0;
    }
  }

  hr {
    margin-top: 10px;
  }

  .message-wrapper {
    padding: 10px;
    background-color: $gray_messages;
    border-radius: 5px;
    margin: 10px 20px;
    position: relative;
    display: block;
    clear: both;
    width: fit-content;
    max-width: 96%;
    float: left;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
    }

    &.own_message {
      background-color: $blue_messages;
      float: right;

      &:after {
        border-color: transparent transparent transparent $blue_messages;
        top: calc(50% - 13px);
        left: inherit;
        right: -20px;
      }
    }

    &.default {
      &:after {
        border-color: transparent $gray_messages transparent transparent;
        left: -20px;
        right: inherit;
        top: calc(50% - 13px);
      }
    }
  }

}

.dropzone {
  .dz-preview {
    margin: 0;
    width: 100%;

    .dz-image {
      background: $gray_messages !important;
      width: 100%;
    }
  }

  border: 2px dashed $gray;
  width: 100%;
  min-height: 70px;
  border-radius: 20px;
  color: $gray;

  .dz-message {
    margin: 0;
  }
}


body.messages {
  .card {
    max-height: 600px;
  }
}

#searchLoader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 11;
  background: #fff;
  left: 0;
  top: 0;

  img {
    width: 90px;
    margin: 0 auto;
    float: none;
    display: block;
    top: 50%;
    position: absolute;
    left: 0;
    right: 0;
  }
}

.size-13-px {
  width: 13px;
  height: 13px;
}

.archived-title, .back-messages {
  cursor: pointer;

  &.hide {
    display: none;
  }
}

#archivedConversations, .archived-title-wrapper, #msgsFromUsers {
  &.hide {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {

  .ipad-col {
    width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

}

