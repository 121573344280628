.card {
  box-shadow: 0px 4px 8px rgba(8, 31, 62, 0.2);

  .card-header {
    padding-bottom: 0;
    background-color: transparent;
  }

  .card-footer {
    padding-top: 0;
    background-color: transparent;
  }

}
.card-deck {
  .card{
    @include media-breakpoint-down(md) {
      flex: none;
      width: fit-content;
      margin-bottom: 24px;
    }
  }
}

.card-body {
  @include media-breakpoint-down(md) {
    padding: 15px;
  }
}

.on-progress-attachments {
  img:not(.mime-icon) {
    width: 25px;
    height: 20px;
    margin-top: 3px;
  }
}

.card.checkout-success-card{
 padding:130px 105px;
}
