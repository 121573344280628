thead {
  th {
    padding-bottom: 30px !important;
  }
}

tbody {
  font-size: 14px;

  tr {
    border-bottom: 1px dashed $blue-light;

    td {
      padding: 20px !important;
      vertical-align: unset;
    }
  }
}

.table {
  .text-nowrap {
    white-space: nowrap;
  }

  td {
    vertical-align: middle;
  }
  thead {
    th {
      padding: 20px !important;
      white-space: nowrap;
    }
  }
}

table.table-borderless {
  color: $gray-darker;
}

#filterLabel {
  .rounded-pill {
    background-color: #DDDDDD;
    width: auto;
    display: inline-block;
    padding: 5px 15px !important;
  }
}

.close-button {
  cursor: pointer;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background: $red;
  color: $white;

  i {
    font-size: 9px;
  }
}

.avatar_find_expertises {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.currency-td{
  white-space: nowrap;
}
