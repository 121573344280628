@media (min-width: 290px) and (max-width: 767.98px) {

  .btn {
    margin-top:0.5em;
    margin-bottom:0.5em;
  }

  .mobile-button-wrapper.mb-2 {
    margin-bottom:unset !important;
  }

  .uploaded-file-wrapper.card{
    width:100% !important;
  }

  #navbarNotificationSystem{
    position: absolute;
    top: 15px;
    left: 65%;
    display: flex;
    max-width: 300px;
    width:45px;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    align-items: center;

    .dropdown-menu {
      left: -69%;
      position: relative;
    }

    .card {
      padding:15px;
    }
  }

  .steps{
    .step-inner{
      margin:10px auto;
    }
  }

  .wrapper_collection {
    .wrapper{
      width:100%;
      text-align: center;
      justify-content: center;
      margin:15px 0 !important;
    }
  }

  .mt-xs{
    margin-top:15px;
  }

  .card-deck {
    .card {
      display: flex;
      justify-content: center;
      width:100%;
    }
  }

  .mobile-button-wrapper{
    width:100%;
  }

  .btn{
    display:block !important;
    width:100% !important;
  }

  .card.checkout-success-card{
    padding:15px !important;
  }
}


//tablets 768 fix

@media (min-width: 767.98px) and (max-width:1025.98px ) {

  .wrapper_collection {
    .wrapper{
      width:30%;
      margin-left: unset !important;
    }
  }

  .card-deck {
    .card {
      display: flex;
      justify-content: center;
      width:100%;
    }
  }

  .justify-center-tablet {
    justify-content: center;
  }

  .btn-tablet {
    display: block;
    width: 100%;
    margin-bottom:1em !important;
  }

  .mb-md-0 {
    margin-bottom:1em !important;
  }

  .tablet-fix{
    flex: 0 0 100% !important;
    max-width:100% !important;
    margin-left:0 !important;
  }

  .tablet-fix-text-align {
    text-align: center !important;
  }

  .d-md-block{
    display:none !important;
  }
}

//all range
@media (min-width: 290px) and (max-width: 1998.98px) {
  .form-row {
    align-items: baseline;
  }

}

@media (min-width: 1210px){
  .table-responsive {
    overflow: hidden;
  }
}
