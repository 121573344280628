.header {
  background: transparent linear-gradient(273deg, #0f3971 0%, color(primary) 100%) 0% 0% no-repeat padding-box;

  .navbar-nav {
    .nav-item > .nav-link {
      font-weight: bold;
      color: #fff;
      padding: 0.5rem 1rem;

      &.dropdown-toggle {
        &::after {
          display: none;
        }
      }
    }

    #navbarAvatar{
      border-radius:100%;
      width: 40px;
      height:40px;
    }
  }
}
