//FONTS
.font-family-base {
  font-family: 'Roboto';
}

.headings-font-family {
  font-family: 'Montserrat';
}

.font-weight-700 {
  font-weight: 700;
}

hr {
  border-top: none;
  border-bottom: 1px dashed $blue-light;
 }

.text-color-blue {
  color:$blue;
}

.card-body-large {
  padding: 100px 60px;

  @include media-breakpoint-down (md) {
    padding: 30px;
  }
}

.ml-55 {
  margin-left:55px;
}

.fs-16{
  font-size:16px;
}

.fs-13{
  font-size:13px;
}

.fs-11{
  font-size:11px;
}

.w-65{
  width:65% !important;
}
