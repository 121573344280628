.fas {
  vertical-align: top;
  font-size: 16px;
}

.search-bar {
  #searchExpertiseData{
    padding-left:32px;
  }
  i.disabled{
    position: absolute;
    top: 34px;
    left: 32px;
    color:#D7D7D7;
  }

  i.fa-arrow-left{
    color:#1278F0;
    cursor:pointer;
  }


  i.enabled {
    position: absolute;
    top: 34px;
    right: 32px;
    color:#1278F0;
    cursor:pointer;
  }
}
