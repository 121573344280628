.modal-content {
  padding: 20px;

  .modal-header {
    padding: 0 0 15px 0;
    border-bottom: 1px dashed $blue-light;
  }

  .modal-body {
    iframe{
      width: 100%;
      height: 75vh;
    }
    padding: 29px 0 0 0;
  }
}

.title-wrapper{
  display: flex;
  justify-content: space-between;

  span.title{
    display: flex;
    width: auto;
    white-space: pre;
    margin-right: 5px;
    align-self: center;
    margin-bottom: 0;
    font-weight:bold;
    font-size:15px;
    font-family:'Roboto';
    color:$primary;
  }

  .line{
    height: 1px;
    background: $blue-light;
    display: flex;
    align-self: center;
    width: 100%;
  }

}

