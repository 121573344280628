.custom-alert{
  background: transparent linear-gradient(267deg, #56D177 0%, #2E7105 100%) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  height: 55px;
  padding-top: 15px;
  color: #fff;
  display: block;
  font: Bold 16px Roboto;

  .close{
    top:4px;
  }
}
