.badge {
  display: block;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;

  &.badge-on-progress{
    background-color:#F9E9E8;
    color:#F8734B;
  }

  &.badge-on-dispute{
    background-color:#FAE09D;
    color:#B38615;
  }

  &.badge-receiving-proposals {
    background-color:#CFE0FF;
    color:#577BBF;
  }

  &.badge-completed {
    background-color:#CCFFE2;
    color:#078F42;
  }

  &.badge-expired {
    background-color: #cccccc;
    color:#666;
  }

  &.badge-archived {
    background-color: #777777;
    color: #fff;
    font-size: 11px !important;
    border-radius: 8px !important;
    padding: 5px 10px;
    font-weight: normal;
  }

}

