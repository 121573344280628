.layers-block {
  position: relative;

  > .layer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;

    &.layer-main {
      min-height:100vh;
      position: relative;
      z-index: 20;
    }
  }

  img.background_image {
    width:100%;
    height:100%;
    position: absolute;
    object-fit: cover;
  }

}
