.form-body {
  .form-group {
    &:focus {
      box-shadow: none;
      border-color: $gray-light;
    }

    &.is-invalid {
      .form-label {
        color: $red;
      }

      small.is-invalid {
        display: block;
      }

      .form-control,
      .form-control:focus {
        border-color: $red;
        background-image: none;
        background-color: #FFF4F2;
        color: $red;
      }
    }
  }

  .form-control {
    &:focus {
      box-shadow: none;
      border-color: $gray-light;
    }

    &.is-invalid {
      border-color: $red;
      background-image: none;
      background-color: #FFF4F2;
      color: $red;

      &:focus {
        border-color: $red;
        background-color: #FFF4F2;
        color: $red;
      }
    }
  }

  .form-label {
    margin-bottom: 0;

    &.profile-margin {
      @include media-breakpoint-down (md) {
        margin-top: 24px;
      }
    ;
    }
  }


  small.is-invalid {
    color: $red;
    font-size: 12px;
    text-align: right;
    padding-top: 3px;
    display: none;
  }

  .custom-control {
    &.custom-checkbox {
      padding-left: 0;

      small.is-invalid {
        position: absolute;
        top: 20px;
        right: 0;

        @include media-breakpoint-down(md) {
          top: 40px;
        }
      }


      .form-check-label {
        padding-left: 30px;

        &::before,
        &::after {
          width: 22px;
          height: 22px;
          top: 0;
          left: 0;
          box-shadow: none !important;
        }

        &::before {
          border: $primary solid 2px;
        }
      }

      .custom-control-input:checked ~ .custom-control-label,
      .custom-control-label ~ .custom-control-input:checked {
        &::before {
          background: $gradient-blue;
          border: 0;
        }
      }
    }
  }
}

::placeholder {
  color: $gray;
}

label.hide-error-message {
  display: none !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .col-form-label {
    text-align: left;
    display: block;
  }
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .align-items-center {
    height: 100%;
  }
}

//form steps

.form-steps {
  .form-step {
    display: none;

    &.active {
      display: block;
    }
  }
}

.steps {
  $thickness: 2px;
  display: flex;
  display: -webkit-flex;

  .step {
    color: $gray;

    label {
      font-size: 13px;
      font-family: Montserrat;
    }

    &.active {
      color: $primary;
      font-weight: bold;

      .line {
        background-color: $primary !important;
      }
    }

  }

  .label-number {
    float: left;
    padding: 0 7px;
    margin-right: 5px;
    font-weight: bold;

    .shape-over-number {
      width: 17px;
      position: absolute;
      height: 18px;
      top: 0;
      left: 0;

      &.bottom {
        bottom: 0;
        right: 0;
        top: auto;
        left: auto;
      }

      > .line {
        background-color: $gray;
        position: absolute;
      }

      > .line-left {
        width: $thickness;
        height: 50%;

      }

      > .line-right {
        width: $thickness;
        height: 50%;
        right: 0;
        bottom: 0;

      }

      > .line-up {
        width: 50%;
        height: $thickness;
        top: 0;
      }

      > .line-bottom {
        width: 50%;
        height: $thickness;
        bottom: 0;
        right: 0;
      }
    }
  }
}


//inputs de radio customizados
.radio-form {
  margin: 0.5rem;
  margin-right: 40px;

  label {
    cursor: pointer;
  }

  input[type="radio"] {
    position: absolute;
    opacity: 0;

    + .radio-label {
      &:before {
        content: '';
        background: $white;
        border-radius: 100%;
        border: 1.5px solid $primary;
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
        margin-right: 10px;
      }
    }

    &:checked {
      + .radio-label {
        &:before {
          background-color: $primary;
          box-shadow: inset 0 0 0 3px $white;
        }
      }
    }

    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $primary;
        }
      }
    }

    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $secondary;
          border-color: darken($secondary, 25%);
          background: darken($secondary, 25%);
        }
      }
    }

    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

//form steps

.form-steps {
  .form-step {
    display: none;

    &.active {
      display: block;
    }
  }
}

.custom-input-backgrounded {
  background: $gradient-blue;
  border-radius: 35px;
  color: $white;
  border: none;
  padding: 4px 5px;
  font-weight: normal;
  font-size: 14px;

  &:hover {
    color: $white;
  }
}

.has-currency {
  display: none;
}

.date-picker, .input-currency {
  padding: 3px !important;
}

.custom-file-label::after {
  content: "Anexar";
  background: transparent url('/img/baseline-attach_file-24px.svg') 5% 38% no-repeat padding-box;
  padding: 0.375rem 1.75rem;
  color: $primary;
  top: 2px;
  height: 40px;
}

.custom-file-label, .custom-file, .custom-file-input {
  height: 40px;
}

.form-control.is-invalid {
  padding-right: 0;
}

a.terms {
  color: $blue;
}

label.hide-error-message.d-block-important {
  display: block !important;
}

.bg-gradient {
  overflow: hidden;
  border-radius: 20px;

  &::before {
    background: transparent linear-gradient(180deg, #5CC7D700 0%, #000000AA 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    content: '';
    display: block;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;

    @include media-breakpoint-down (md) {
      border-radius: 40px;
    }
  }

  .change-image {
    position: absolute;
    bottom: 10px;
    right: 32px;
    opacity: 0;

    @include media-breakpoint-down (md) {
      right: 0;
      left: 0;
      margin: 0 auto;
      text-align: center;
      bottom: 30px;
    }
  }

  &:hover {
    &::before {
      top: 0;
      transition: top 0.3s linear;
    }

    .change-image {
      opacity: 1;
    }
  }
}

.slider_range {
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 7px;
    border-radius: 5px;
    background: $blue-light;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $gradient-blue;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $gradient-blue;
    cursor: pointer;
  }
}

.range_slider_value {
  color: $primary;
  font-family: Roboto;
  font-weight: bold;
  margin: 0 auto;
  position: relative;
  right: 0;
  float: none;
  width: max-content;
}

#file_upload {
  .trigger-file-upload.position-absolute {
    left: 0;
    top: 0;
    cursor: pointer;
  }
}

.qs-datepicker-container {
  background: transparent;
  min-width: 245px;
  border: none;
  font-size: 13px;

  .qs-datepicker {
    background-color: #FAFAFA !important;

    .qs-month-year {
      transition: none;
    }

    .qs-square.qs-day {
      color: $primary;
    }

    .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
      background: transparent;
    }

    .qs-current {
      .qs-num {
        background: $gradient-blue;
        border-radius: 100px;
        color: $white;
        width: 23px;
        height: 23px;
        text-align: center;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .qs-square {
      width: 26px;
      height: 26px;
    }

    .custom_arrow_wrapper {
      float: right;
      display: flex;
    }

    .custom_year_wrapper {
      float: left;
      display: flex;
    }

    .qs-controls, .qs-squares {
      background: transparent;
      padding: 30px;
    }
  }
}

.hide-step {
  display: none;
}

#form-create-expertise {
  .form-row {
    margin-bottom: 25px !important;
  }

  label {
    color: $primary;
  }
}

#file.small_attachment {
  display: flex;
  border-radius: 20px;
  border: 2px dashed $gray;
  left: 0;
  position: relative;
  top: 0;
  cursor: pointer;

  span {
    margin: 0 0 0 15px
  }
}

.input-ball {
  width: 27px;
  height: 27px;
  background: $gray;
  border-radius: 100%;
  left: -2px;
  top: -2px;
  position: absolute;
}

.on-progress-attachments {
  .dropzone {
    padding: 0;
    min-height: unset;
    background: unset;
  }

  .dz-preview {
    left: 0;
    right: 0;
    margin: 0 auto !important;
    width: 50% !important;
    position: fixed;
    top: 50%;
    background: #fff;
    text-align: center;
    z-index: 1;
    border-radius: 18px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

.dz-error-mark {
  background: $red;
  border-radius: 100%;
}

.badge-checkboxes .checkbox input[type="checkbox"],
.badge-checkboxes label.checkbox-inline input[type="checkbox"] {
  /*  Hide the checkbox, but keeps tabbing to it possible. */
  position: absolute;
  clip: rect(0 0 0 0);
}

.badge-checkboxes .checkbox input[type="checkbox"] + .badge,
.badge-checkboxes label.checkbox-inline input[type="checkbox"] + .badge {
  //border:1px solid #999; /* Add outline to badge */

  /* Make text in badge not selectable */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -html-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*The following css only required for Bootstrap <= 3.1.0 */
.badge-checkboxes .checkbox {
  padding-left: 0; /* Remove space normally used for the checkbox */
}

.badge-checkboxes {
  label {
    padding: 5px 15px;
  }
}

.input-group.bank-agency {
  width: 100%;

  .is-invalid {
    position: absolute;
    left: 0;
    top: 35px;
  }

  .input-group-append {
    .is-invalid {
      position: absolute;
      top: 35px;
      right: 0;
    }
  }

  .input-group-append {
    width: 30%;
    margin-left: -18px;
  }

  .agency {
    padding: 0 30px;
  }

  .digit {
    padding-left: 20px;
    width: 100%;
  }
}

#filename {
  color: $blue-light;
  overflow: hidden;
}

.uploaded-files {
  .uploaded-file-wrapper {
    padding: 5px 5px;
    flex-direction: initial !important;
    border-radius: 10px;
    min-height: 50px;
    align-content: center;
    align-items: center;
    width: 45%;
    position: relative;
  }

  .close-button.big {
    position: absolute;
    right: 5px;
    top: 5px;
  }

}

//SELECT2 estilizações
.select2-container--default .select2-selection--single {
  background-color: #FAFAFA;
  border: 1px solid #e2e0de;
  border-radius: 18px;
}

.select2-container .select2-selection--single {
  height: calc(1.6em + 0.75rem + 2px);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  b {
    border-width: 7px 3px 1px 3px;
    border-color: #495057 transparent transparent transparent;
    margin-top: -5px;
  }

  height: calc(1.6em + 0.75rem + 2px);
}

.select2-container--default .select2-selection--single .select2-selection__placeholder{
  color:#1A1D21;
}

.select2-container--open .select2-dropdown--below {
  padding: 10px;
  border-radius: 18px;
  box-shadow: 0px 3px 6px #00000029;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: #FAFAFA;
  border: none;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #E2E0DE;
  border-radius: 10px;
  height: 40px;
}

 .select2-results__option{
  font-family: Roboto;
   color:#1A1D21;
   font-size:14px;
 }

.select2-container--default .select2-selection--single .select2-selection__rendered{
  line-height:23px;
}

.select2-container--default .select2-results__option[aria-selected=true], .select2-container--default .select2-results__option--highlighted[aria-selected]{
  background-color: transparent;
  color: inherit;
}

.search-bar{
  padding: 20px;
  background-color: #081F3E33;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
}
