h1 {
  font-size:40px;
  color:$blue-light;
}

.opacity-0 {
  opacity:0 !important;
}

body.my-expertises, body.find_expertises {
  .messages_wrapper {
    .alert-success{
      display:none;
    }
  }
}

.text-pre-line {
  white-space: pre-line !important;
}

#hideAll
{
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 99999; /* Higher than anything else in the document */

}

body.termos-de-servico h2{
  text-transform: capitalize;
  margin-top: 50px;
  text-align: left;
}

body.termos-de-servico h3{
  text-transform: lowercase;
  font-size:1.1em;
  margin-top: 30px;
}
