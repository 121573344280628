$margin-left-right:14%;
$margin-top-bottom:7%;
$square_size:100px;

.shape-over-image {
  width: $square_size;
  position: absolute;
  margin-top: $margin-top-bottom;
  height: $square_size;
  margin-left: $margin-left-right;
  max-width: 100px;
  top:0;

  &.bottom{
    bottom: 0;
    margin-bottom: $margin-top-bottom;
    margin-right: $margin-left-right;
    right: 0;
    top:auto;
  }

  >.line {
    background-color: $white;
  }

  >.line-left {
    width: 20px;
    height: 100%;

  }

  >.line-right {
    width: 20px;
    height: 100%;
    float: right;
  }

  >.line-up{
    width:100%;
    height:20px;
    position: absolute;
    top:0;
  }

  >.line-bottom{
    width:100%;
    height:20px;
    position: absolute;
    bottom:0;
  }

  > img.logo-side-image{
    width: 100%;
    top: 60%;
    position: absolute;
    left: 60%;
  }
}

.side-bg-image {
  position: relative;
  background-size: cover;
  height:100%;
  min-height: 100vh;
}
